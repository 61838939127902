import React, { useState } from 'react'
import axios from "axios";
import './style.scss'


const ForgotPassword = () => {
  const [recoveryEmail, setRecoveryEmail] = useState("")
  const [forgotPasswordSubmitted, setForgotPasswordSubmitted] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [validEmailError, setValidEmailError] = useState(false)

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const resetPassword = (e) => {
    e.preventDefault();
    setResetPasswordLoading(true)
    setValidEmailError(false)

    if (validateEmail(recoveryEmail)) {
      axios.post(`${process.env.GATSBY_API_URL}/api/auth/password`, {auth: {email: recoveryEmail} }).then((res) => {
        setResetPasswordLoading(false)
        setForgotPasswordSubmitted(true)
        setRecoveryEmail('')
      }).catch((err) => {
        setResetPasswordLoading(false)
        setForgotPasswordSubmitted(true)
        setRecoveryEmail('')
      })
    } else {
      setValidEmailError(true)
    }
  }

  return (
    <main className="forgot-password-page main-content grid-section" role="main">
      <div className="login-wrapper">
        <form method="post">
          <div className="container">
            <div className="grid__item title one-whole text-center">
              <h1 className="heading">Forgot Password</h1>
            </div>
            <p className='subheader'>Forgot your password? Enter your email address below and we will send you instructions to reset it.</p>
            <div className="input-container">
              <label htmlFor="CustomerEmail" className="label">
                Email Address
              </label>
              <input type="email" name="email" value={recoveryEmail} onChange={(e) => setRecoveryEmail(e.target.value)} id="CustomerEmail" className="input-full" placeholder="Enter your email address" autoCorrect="off" autoCapitalize="off" autoFocus="" />

              <button disabled={recoveryEmail === '' || resetPasswordLoading} className='login-button reset-password-button' onClick={(e) =>  resetPassword(e)}>
                Send Reset Password Email
              </button>

              {validEmailError &&
                <h5 className='text-center mt-5'>Please input a valid email</h5>
              }

              {forgotPasswordSubmitted &&
                <div>
                  <h5 className='text-center mt-5'>Reset Password Email Sent</h5>
                  <h5 className='text-center mt-3'>Back to <a href='/login'>login</a></h5>
                </div>
              }
            </div>
          </div>
        </form>
      </div>
    </main >
  )
}

export default ForgotPassword
