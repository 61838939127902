import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ForgotPasswordDevise from '../components/ForgotPasswordDevise'

const ForgotPasswordDevisePage = () => {

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/forgot-password",
      "@type": "webpage",
      "name": "Forgot Password: Reset Hubble Member Portal Password | Hubble",
      "description": "Forgot your Hubble member portal password? Not a problem. Enter your email address here to receive instructions on how to easily reset your password and regain access to your account.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]



  return (
    <Layout>
      <SEO title="Forgot Password: Reset Hubble Member Portal Password"
        description="Forgot your Hubble member portal password? Not a problem. Enter your email address here to receive instructions on how to easily reset your password and regain access to your account."
        schemaMarkup={schemaMarkup}/>
      <ForgotPasswordDevise />
    </Layout>
  )
}

export default ForgotPasswordDevisePage
